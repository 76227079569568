// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-js": () => import("./../../../src/pages/about/About.js" /* webpackChunkName: "component---src-pages-about-about-js" */),
  "component---src-pages-contact-contact-js": () => import("./../../../src/pages/contact/Contact.js" /* webpackChunkName: "component---src-pages-contact-contact-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-industrial-automation-industrial-automation-js": () => import("./../../../src/pages/portfolio/industrial-automation/industrial-automation.js" /* webpackChunkName: "component---src-pages-portfolio-industrial-automation-industrial-automation-js" */),
  "component---src-pages-portfolio-mobile-mobile-js": () => import("./../../../src/pages/portfolio/mobile/mobile.js" /* webpackChunkName: "component---src-pages-portfolio-mobile-mobile-js" */),
  "component---src-pages-portfolio-portfolio-js": () => import("./../../../src/pages/portfolio/Portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-js" */),
  "component---src-pages-portfolio-random-spanish-random-spanish-js": () => import("./../../../src/pages/portfolio/random-spanish/random-spanish.js" /* webpackChunkName: "component---src-pages-portfolio-random-spanish-random-spanish-js" */),
  "component---src-pages-portfolio-web-apps-web-apps-js": () => import("./../../../src/pages/portfolio/web-apps/web-apps.js" /* webpackChunkName: "component---src-pages-portfolio-web-apps-web-apps-js" */)
}

